const slick = require('slick-carousel');

import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';

$(document).ready(function () {
    $('.slick-carousel').slick({
        dots: false,
        speed: 500,
        slidesToShow: 18,
        slidesToScroll: 6,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 10,
                    slidesToScroll: 5,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });
});